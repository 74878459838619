import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql, Link } from "gatsby";
import Col from 'react-bootstrap/Col';
import Headline from "../components/Headline"
import Teaser from "../components/Teaser";
import Section from "../components/Section"
import { getTopicsFromResources } from "../services/utility";

export default class Topic extends React.Component {
  render() {
    let selectedTopic = this.props.data.contentfulTopic;
    let allResources = this.props.data.resources.edges.map(({ node }) => node).filter(function(item) {
      return (item.topics && item.topics.length > 0);
    });
    let filteredResources = allResources.filter(function(item) {
      return (item.topics && item.topics.map(({ slug }) => slug).includes(selectedTopic.slug))
    });
    return ( 
      <Layout>
        <Seo title={`${selectedTopic.name} Resources`} />
        <Section className="white" useCol={false}>
          <Col xs={12} md={4} lg={3}>
            <Headline>Resources</Headline>
          </Col>
          <Col xs={12} md={8} lg={9} className="pb-3 pt-lg-2">
              <Link to={"/resources/all"} className={"mx-1 badge badge-pill badge-primary"}>All</Link>
              {getTopicsFromResources(allResources).map((topic) => {
                return (
                  <Link to={"/resources/topic-" + topic.slug} key={"topic-" + topic.slug} className={"mx-1 badge badge-pill badge-primary" + (topic.slug === selectedTopic.slug ? " active" : "")}>{topic.name}</Link>
                );
              })}
          </Col>
          {filteredResources.map((resource) => {
            return (
              <Col md={4} key={"resource-" + resource.slug} className="gridListing mb-4">
                <Teaser content={resource} showDescription={true} />
              </Col>
            );
          })}
        </Section>
      </Layout>
    );
  }
}
export const topicFragment = graphql`
  fragment Topic on ContentfulTopic {
    name
    slug
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        width: 350
        )
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`
export const pageQuery = graphql`
query TopicBySlug($slug: String!) {
    contentfulTopic(slug: { eq: $slug }) {
      ...Topic
    }
    resources: allContentfulVideoResource(filter: {showInResources: {ne: false}}, sort: {fields: [sortYear, sortOrder], order: [DESC, ASC]}) {
      edges {
        node {
          ...VideoResource
          topics {
            name
            slug
          }
        }
      }
    }    
  }
`